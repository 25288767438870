export const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4011"
    : "https://api.billing.proip.info";

export const STRIPY_KEY =
  process.env.NODE_ENV === "development"
    ? "pk_test_GFnBfe9vZjWciVWdQru0A3Mj00SigBm34d"
    : "pk_live_Wq9kBTyLLbWaRBwBKOBCn6NN000BuNaKT8";

export const DOWNLOAD_URL =
  process.env.NODE_ENV === "development" ? "http://localhost:4011" : "https://download.proip.info";

export default API_URL;
