import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import { getToken } from "@/common/jwt.service";

require("@/assets/scss/main.scss");

const app = createApp(App);

router.beforeEach((to, from, next) => {
  if (
    to.path !== "/login" &&
    to.path !== "/register" &&
    to.path !== "/new-password" &&
    to.path !== "/recovery" &&
    to.path !== "/forgot-password" &&
    to.path !== "/social"
  ) {
    const token = getToken();
    if (!token || token === "undefined") {
      next({ name: "Login" });
    } else {
      store.dispatch("auth/me");
      next();
    }
  } else {
    next();
  }
});

app.use(store).use(router).use(antd).mount("#app");
