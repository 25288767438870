import { notification } from "ant-design-vue";

type NotifyType = "success" | "info" | "warning" | "error";

export default function () {
  const sendNotify = (type: NotifyType, message: string, description: string) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  return { sendNotify };
}
