const ID_TOKEN_KEY = "id_token";

// @TODO find a better way to make it work
interface Process {
  browser: boolean;
}
declare let process: Process;

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const getToken = () => (process.browser ? localStorage.getItem(ID_TOKEN_KEY) : "");

export const saveToken = (token: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  if (process.browser) {
    localStorage.setItem(ID_TOKEN_KEY, token);
  }
};

export const destroyToken = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  if (process.browser) {
    window.localStorage.removeItem(ID_TOKEN_KEY);
  }
};

export default { getToken, saveToken, destroyToken };
