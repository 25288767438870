
import { defineComponent, reactive, computed, onMounted, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { convertMoney } from "@/common/helper";
import { useStore } from "vuex";
import {
  CheckCircleOutlined,
  PlusCircleOutlined,
  FileDoneOutlined,
  ShareAltOutlined,
  DollarOutlined,
} from "@ant-design/icons-vue";

export default defineComponent({
  name: "TheSaidNav",
  components: {
    CheckCircleOutlined,
    PlusCircleOutlined,
    FileDoneOutlined,
    ShareAltOutlined,
  },
  setup() {
    const selectedKeys: string[] = reactive([]);

    const store = useStore();
    const route = useRoute();

    const credits = computed(() => {
      return store.getters["auth/getCredits"];
    });

    const loadKeys = () => {
      if (typeof route.name !== "undefined" && route.name) {
        selectedKeys.splice(0, selectedKeys.length, route.name.toString());
      }
    };

    onMounted(loadKeys);

    watchEffect(() => {
      if (typeof route.name !== "undefined" && route.name) {
        selectedKeys.splice(0, selectedKeys.length, route.name.toString());
      }
    });

    const converter = convertMoney;

    return { converter, credits, store, selectedKeys };
  },
});
