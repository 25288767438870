import { AuthState } from "@/types/AuthState";
import { RootState } from "@/types/RootState";
import { ActionContext } from "vuex";
import { destroyToken, getToken, saveToken } from "@/common/jwt.service";
import ApiService from "@/api/api.service";
import { User } from "@/types/User";

type AuthContext = ActionContext<AuthState, RootState>;

const state: AuthState = {
  errors: {},
  user: {
    token: "",
    credits: 0,
    email: "",
    timezone: "UTC",
  },
  isAuthenticated: !!getToken(),
  active: false,
  version: "0.0.0",
  update: false,
};

const getters = {
  currentUser(state: AuthState) {
    if (state.user) {
      return state.user;
    }

    return null;
  },
  isAuthenticated(state: AuthState) {
    return state.isAuthenticated;
  },
  getCredits(state: AuthState) {
    if (state.user) {
      return state.user.credits;
    }

    return 0;
  },
};

const actions = {
  login(context: AuthContext, credentials: object) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/auth/login", credentials)
        .then(({ data }) => {
          if (data.error) {
            context.commit("setError", data.error);
            reject(data.error);
          }
          // eslint-disable-next-line no-param-reassign
          data.user.token = data.accessToken;
          document.cookie = `user=${data.user.email}; domain=.proip.info`;
          context.commit("setAuth", data.user);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // eslint-disable-next-line consistent-return
  active(context: AuthContext) {
    if (getToken()) {
      return new Promise((resolve) => {
        ApiService.get("api/auth/user-info-check").then(({ data }) => {
          if (typeof data.active !== "undefined" && data.active) {
            context.commit("setActive", data.active);
          }
          resolve();
        });
      });
    }
  },
  logout(context: AuthContext) {
    document.cookie = "user=none; domain=.proip.info";
    context.commit("purgeAuth");
  },
  register(context: AuthContext, credentials: object) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/auth/register", credentials)
        .then(({ data }) => {
          // eslint-disable-next-line no-param-reassign
          data.user.token = data.accessToken;
          context.commit("setAuth", data.user);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line consistent-return
  me(context: AuthContext) {
    if (getToken()) {
      return new Promise((resolve) => {
        ApiService.get("api/auth/me")
          .then(({ data }) => {
            if (typeof data.user !== "undefined") {
              // eslint-disable-next-line no-param-reassign
              data.user.token = data.accessToken;
              context.commit("setAuth", data.user);
              context.commit("setVersion", data.version);
            } else {
              context.commit("purgeAuth");
            }
            resolve();
          })
          .catch((response) => {
            if (response.status === 401) {
              context.commit("setError", response.status);
              context.commit("purgeAuth");
            }
          });
      });
    }
    context.commit("purgeAuth");
  },
};

const mutations = {
  setError(state: AuthState, error: object) {
    state.errors = error;
  },
  setAuth(state: AuthState, user: User) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    saveToken(state.user.token);
  },
  setActive(state: AuthState, active: boolean) {
    state.active = active;
  },
  setVersion(state: AuthState, version: string) {
    if (state.version !== version && state.version !== "0.0.0") {
      window.location.reload(true);
    }
    state.version = version;
  },
  purgeAuth(state: AuthState) {
    state.isAuthenticated = false;
    state.active = false;
    delete state.user;
    state.errors = {};
    destroyToken();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
