
import { defineComponent, computed } from "vue";
import TheHeader from "@/components/TheHeader.vue";
import TheMenu from "@/components/TheMenu.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "App",
  components: {
    TheHeader,
    TheMenu,
  },
  setup() {
    const route = useRoute();
    const date = new Date();

    const withOutSideBar = ["Login", "Register", "UserBillingInfo", "Social", "ForgotPassword"];

    const checkSideBar = computed(() => {
      if (typeof route.name !== "undefined" && route.name) {
        if (withOutSideBar.indexOf(route.name.toString()) !== -1) {
          return "auth";
        }

        return "main";
      } else {
        return "loading";
      }
    });

    return { checkSideBar, date };
  },
});
