import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TheStart = _resolveComponent("TheStart")
  const _component_a_spin = _resolveComponent("a-spin")
  const _component_TheLicense = _resolveComponent("TheLicense")
  const _component_a_list_item = _resolveComponent("a-list-item")
  const _component_a_list = _resolveComponent("a-list")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (!_ctx.isLoading && _ctx.licenses.length === 0)
      ? (_openBlock(), _createBlock(_component_TheStart, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.isLoading && _ctx.licenses.length > 0)
      ? (_openBlock(), _createBlock("h1", _hoisted_2, "Licenses"))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_a_spin, { key: 2 }))
      : _createCommentVNode("", true),
    (!_ctx.isLoading && _ctx.licenses.length > 0)
      ? (_openBlock(), _createBlock(_component_a_list, {
          key: 3,
          grid: { gutter: 16, xs: 1 },
          "data-source": _ctx.licenses
        }, {
          renderItem: _withCtx(({ item, index }) => [
            _createVNode(_component_a_list_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_TheLicense, { "initial-license": item }, null, 8, ["initial-license"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["data-source"]))
      : _createCommentVNode("", true)
  ]))
}