import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "logo-full" }
const _hoisted_2 = { "clip-path": "url(#clip0)" }
const _hoisted_3 = { id: "clip0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock("svg", {
      viewBox: "0 0 254 90",
      style: { width: _ctx.width },
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }, [
      _createVNode("path", {
        d: "M119.432 22C123.315 22 126.685 22.64 129.544 23.92C132.445 25.2 134.664 27.0347 136.2 29.424C137.736 31.8133 138.504 34.6507 138.504 37.936C138.504 41.1787 137.736 44.016 136.2 46.448C134.664 48.8373 132.445 50.672 129.544 51.952C126.685 53.232 123.315 53.872 119.432 53.872H109.32V66.8H101V22H119.432ZM119.048 46.832C122.675 46.832 125.427 46.064 127.304 44.528C129.181 42.992 130.12 40.7947 130.12 37.936C130.12 35.0773 129.181 32.88 127.304 31.344C125.427 29.808 122.675 29.04 119.048 29.04H109.32V46.832H119.048Z",
        fill: _ctx.color
      }, null, 8, ["fill"]),
      _createVNode("path", {
        d: "M149.348 37.616C151.652 34.032 155.705 32.24 161.508 32.24V39.856C160.825 39.728 160.207 39.664 159.652 39.664C156.537 39.664 154.105 40.5813 152.356 42.416C150.607 44.208 149.732 46.8107 149.732 50.224V66.8H141.732V32.624H149.348V37.616Z",
        fill: _ctx.color
      }, null, 8, ["fill"]),
      _createVNode("path", {
        d: "M181.342 67.248C177.886 67.248 174.771 66.5013 171.998 65.008C169.225 63.5147 167.049 61.4453 165.47 58.8C163.934 56.112 163.166 53.0827 163.166 49.712C163.166 46.3413 163.934 43.3333 165.47 40.688C167.049 38.0427 169.225 35.9733 171.998 34.48C174.771 32.9867 177.886 32.24 181.342 32.24C184.841 32.24 187.977 32.9867 190.75 34.48C193.523 35.9733 195.678 38.0427 197.214 40.688C198.793 43.3333 199.582 46.3413 199.582 49.712C199.582 53.0827 198.793 56.112 197.214 58.8C195.678 61.4453 193.523 63.5147 190.75 65.008C187.977 66.5013 184.841 67.248 181.342 67.248ZM181.342 60.4C184.286 60.4 186.718 59.4187 188.638 57.456C190.558 55.4933 191.518 52.912 191.518 49.712C191.518 46.512 190.558 43.9307 188.638 41.968C186.718 40.0053 184.286 39.024 181.342 39.024C178.398 39.024 175.966 40.0053 174.046 41.968C172.169 43.9307 171.23 46.512 171.23 49.712C171.23 52.912 172.169 55.4933 174.046 57.456C175.966 59.4187 178.398 60.4 181.342 60.4Z",
        fill: _ctx.color
      }, null, 8, ["fill"]),
      _createVNode("path", {
        d: "M204 22H212.32V66.8H204V22Z",
        fill: _ctx.color
      }, null, 8, ["fill"]),
      _createVNode("path", {
        d: "M234.869 22C238.752 22 242.123 22.64 244.981 23.92C247.883 25.2 250.102 27.0347 251.637 29.424C253.173 31.8133 253.941 34.6507 253.941 37.936C253.941 41.1787 253.173 44.016 251.637 46.448C250.102 48.8373 247.883 50.672 244.981 51.952C242.123 53.232 238.752 53.872 234.869 53.872H224.757V66.8H216.438V22H234.869ZM234.485 46.832C238.112 46.832 240.864 46.064 242.742 44.528C244.619 42.992 245.557 40.7947 245.557 37.936C245.557 35.0773 244.619 32.88 242.742 31.344C240.864 29.808 238.112 29.04 234.485 29.04H224.757V46.832H234.485Z",
        fill: _ctx.color
      }, null, 8, ["fill"]),
      _createVNode("g", _hoisted_2, [
        _createVNode("path", {
          d: "M28.5 85.2C24.1 82.5 16.8 78.2 12.2 75.6C-0.500021 68.2 -2.15794e-05 69.5 -2.15794e-05 44.5V23.3L2.69998 20.6C4.09998 19.1 7.79998 16.5 10.9 14.6C14 12.8 20.8 8.80002 26 5.70002C38.2 -1.59998 41 -1.79998 51.5 4.30002C55.6 6.60002 58.9 9.00002 58.9 9.50002C58.8 10.1 55.6 13.4 51.7 16.9C47.7 20.5 39.5 28.1 33.4 33.9C27.4 39.7 19.1 47.6 15.2 51.3C9.49998 56.7 7.99998 58.8 7.99998 61.1C7.99998 64.2 12.4 68.1 13.9 66.4C14.4 65.9 25 53.4 37.4 38.5C58.6 13.3 64 8.00002 64 12.5C64 14.2 55.3 28.9 40 53C26 75.2 26.6 74 28.1 75.4C28.9 76.1 31.6 77.9 34.3 79.4L39 82.1L39.9 79.7C41.8 74.7 55.2 42.2 57.9 36C59.5 32.4 62 26.4 63.6 22.5C65.1 18.7 66.7 15.1 67.1 14.6C68.2 13.3 78 19.9 79.1 22.7C79.6 24 80 34.3 80 45.4C80 68.8 80.1 68.4 70.2 74.3C38.7 92.9 40.5 92.5 28.5 85.2ZM72 44.5C72 31.8 71.6 24.9 70.9 24.5C70.3 24.1 69.7 24 69.4 24.2C69.1 24.6 57 71.6 57 72.6C57 72.8 60.4 70.9 64.5 68.4L72 63.9V44.5ZM12.5 36.8C14.9 35 20 31.3 23.7 28.7C27.4 26.1 34.4 21.1 39.2 17.6L47.9 11.4L45.1 9.70002C40 6.70002 39.5 6.90002 17.5 20.1L8.49998 25.5L7.99998 32.8C7.69998 36.7 7.59998 40 7.79998 40C7.99998 40 10.1 38.5 12.5 36.8Z",
          fill: _ctx.color
        }, null, 8, ["fill"])
      ]),
      _createVNode("defs", null, [
        _createVNode("clipPath", _hoisted_3, [
          _createVNode("rect", {
            width: "80",
            height: "90",
            fill: _ctx.color
          }, null, 8, ["fill"])
        ])
      ])
    ], 4))
  ]))
}