
import { defineComponent } from "vue";

export default defineComponent({
  name: "TheLogo",
  components: {},
  props: {
    color: String,
    width: String,
  },
});
