
import { defineComponent, ref, reactive, onMounted } from "vue";
import TheStart from "@/components/TheStart.vue";
import TheLicense from "@/components/TheLicense.vue";
import { License } from "@/types/License";
import { apiGetLicenses } from "@/api/licenses";

export default defineComponent({
  name: "Home",
  components: {
    TheStart,
    TheLicense,
  },
  setup() {
    const isLoading = ref(false);
    const licenses: License[] = reactive([]);

    const loadLicenses = async () => {
      isLoading.value = true;
      const { data } = await apiGetLicenses();
      licenses.splice(0, licenses.length, ...data);
      isLoading.value = false;
    };

    onMounted(loadLicenses);

    return { isLoading, licenses };
  },
});
