
import { computed, defineComponent, onMounted, reactive, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { convertMoney } from "@/common/helper";
import { DollarOutlined, SettingOutlined, LogoutOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "TheMenuMobile",
  components: { DollarOutlined, SettingOutlined, LogoutOutlined },
  setup(props, { emit }) {
    const selectedKeys: string[] = reactive([]);

    const store = useStore();
    const route = useRoute();

    const credits = computed(() => {
      return store.getters["auth/getCredits"];
    });

    const loadKeys = () => {
      if (typeof route.name !== "undefined" && route.name) {
        selectedKeys.splice(0, selectedKeys.length, route.name.toString());
      }
    };

    onMounted(loadKeys);

    watchEffect(() => {
      if (typeof route.name !== "undefined" && route.name) {
        selectedKeys.splice(0, selectedKeys.length, route.name.toString());
      }
    });

    const logOut = () => {
      emit("logout");
    };

    const converter = convertMoney;

    return { converter, credits, store, selectedKeys, logOut };
  },
});
