
import { defineComponent, reactive } from "vue";
import { DOWNLOAD_URL } from "@/common/config";
import { DownloadOutlined, CopyOutlined, CalendarOutlined } from "@ant-design/icons-vue";
import { License } from "@/types/License";
import * as dayjs from "dayjs";
import { apiUpdateLicenseSettings } from "@/api/licenses";
import useNotify from "@/composables/useNotify";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { toClipboard } from "@soerenmartius/vue3-clipboard";

export default defineComponent({
  name: "TheLicenses",
  components: {
    DownloadOutlined,
    CopyOutlined,
    CalendarOutlined,
  },
  props: {
    initialLicense: {
      type: Object as () => License,
      required: true,
    },
  },
  setup(props) {
    dayjs.extend(localizedFormat);
    const url = DOWNLOAD_URL;
    const license: License = reactive(props.initialLicense);

    const renewStatus = () => {
      if (typeof license.setting?.renew !== "undefined") {
        return license.setting?.renew;
      }
      return true;
    };

    const onChange = async () => {
      const { id } = license;
      if (typeof id !== "undefined") {
        const { data } = await apiUpdateLicenseSettings(id, !renewStatus());
        Object.assign(license, data);
      }
    };

    const downloadBase = () => {
      window.open(`${url}/database/${license.uuid}`, "_self");
    };

    const notify = useNotify();

    const openNotificationCopy = (type: string) => {
      if (type === "Token") {
        toClipboard(`${license.uuid}`);
      }
      notify.sendNotify("success", "Copied", `${type} copied to clipboard`);
    };

    return {
      license,
      onChange,
      downloadBase,
      openNotificationCopy,
      renewStatus,
      dayjs,
    };
  },
});
