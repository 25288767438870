import service from "@/api/api-transport";

const ApiService = {
  query(resource: string, params: object) {
    return service.get(`${resource}`, { params }).catch((error) => Promise.reject(error.response));
  },

  get(resource: string) {
    return service.get(`${resource}`).catch((error) => Promise.reject(error.response));
  },

  post(resource: string, params: object) {
    return service.post(`${resource}`, params).catch((error) => Promise.reject(error.response));
  },

  put(resource: string, params: object) {
    return service.put(`${resource}`, params).catch((error) => Promise.reject(error.response));
  },

  delete(resource: string) {
    return service.delete(`${resource}`).catch((error) => Promise.reject(error.response));
  },
};

export default ApiService;
