
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "TheStart",
  components: {},
  props: {
    toggleSideBar: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    const toggleBodyClass = (addRemoveClass: string, className: string) => {
      const el = document.body;

      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    };
    const mount = () => {
      toggleBodyClass("addClass", "start-body");
    };
    onMounted(mount);

    const unMount = () => {
      toggleBodyClass("removeClass", "start-body");
    };
    onUnmounted(unMount);

    return { router };
  },
});
