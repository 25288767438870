import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import API_URL from "@/common/config";
import { getToken } from "@/common/jwt.service";
import applyCaseMiddleware from "axios-case-converter";
import { notification } from "ant-design-vue";

const service: AxiosInstance = applyCaseMiddleware(
  axios.create({
    baseURL: API_URL,
    timeout: 10000,
  })
);

service.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = getToken();
    if (token !== "") {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (err: AxiosError) => {
    return Promise.reject(err);
  }
);

type ApiErrors = { [property: string]: string[] };

const getErrors = (errors: ApiErrors) => {
  if (typeof errors !== "undefined") {
    return Object.keys(errors)
      .map(function (key) {
        return errors[key];
      })
      .join(", ");
  }
};

service.interceptors.response.use(
  (res: AxiosResponse): AxiosResponse => {
    return res;
  },
  (err: AxiosError) => {
    if (
      err.response &&
      err.response.status &&
      err.response.status !== 401 &&
      err.response.data.message
    ) {
      notification.error({
        message: err.response.data.message,
        description: getErrors(err.response.data.errors),
      });
    }
    return Promise.reject(err);
  }
);

export default service;
