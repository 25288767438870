import { createStore } from "vuex";

import createPersistedState from "vuex-persistedstate";

import auth from "./auth.module";
import database from "./database.module";

export default createStore({
  modules: {
    auth,
    database,
  },
  plugins: [createPersistedState()],
});
