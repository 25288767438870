export const prettifyNumber = (num: number) => {
  const n = num.toString();
  const separator = " ";
  return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, `$1${separator}`);
};

export const convertMoney = (value: number): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

export default {
  prettifyNumber,
  convertMoney,
};
