import { DatabaseState } from "@/types/DatabaseState";
import { Database } from "@/types/Database";
import { RootState } from "@/types/RootState";
import { ActionContext } from "vuex";

type LangContext = ActionContext<DatabaseState, RootState>;

const state: DatabaseState = {
  databases: [],
};

const getters = {
  getPlan: (state: DatabaseState) => (id: number) => {
    state.databases.find((item) => item.id === id);
  },
};

const actions = {
  setDatabases(context: LangContext, databases: Database[]) {
    context.commit("setDatabases", databases);
  },
};

const mutations = {
  setDatabases(state: DatabaseState, databases: Database[]) {
    state.databases = databases;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
