import ApiService from "@/api/api.service";
import { AxiosPromise } from "axios";
import { License } from "@/types/License";

type LicenseStartParams = {
  database: number;
  month: number;
};

export const apiStartLicense = (params: LicenseStartParams): AxiosPromise => {
  return ApiService.post("api/billing/licenses/start", params);
};

export const apiGetLicenses = (): AxiosPromise<License[]> => {
  return ApiService.get("api/billing/licenses");
};

export const apiUpdateLicenseSettings = (id: number, renew: boolean): AxiosPromise<License> => {
  return ApiService.put(`api/billing/licenses/setting/renew/${id}`, {
    renew,
  });
};
