
import { defineComponent, ref } from "vue";
import TheLogo from "@/components/TheLogo.vue";
import TheMenuMobile from "@/components/TheMenuMobile.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { MenuOutlined, SettingOutlined, LogoutOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "TheHeader",
  components: {
    TheLogo,
    TheMenuMobile,
    MenuOutlined,
    SettingOutlined,
    LogoutOutlined,
  },
  setup() {
    const idDisplayMobileMenu = ref(false);

    const router = useRouter();
    const store = useStore();

    const mobileMenuStatus = () => {
      idDisplayMobileMenu.value = !idDisplayMobileMenu.value;
    };

    const logOut = async () => {
      await store.dispatch("auth/logout");
      await router.push({ name: "Login" });
    };

    return { idDisplayMobileMenu, mobileMenuStatus, logOut, store };
  },
});
